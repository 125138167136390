import { render, staticRenderFns } from "./NewsTicker.vue?vue&type=template&id=252b9905&scoped=true&"
import script from "./NewsTicker.vue?vue&type=script&lang=ts&"
export * from "./NewsTicker.vue?vue&type=script&lang=ts&"
import style0 from "./NewsTicker.vue?vue&type=style&index=0&id=252b9905&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252b9905",
  null
  
)

export default component.exports